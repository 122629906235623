import { Box, Center, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { useForm } from '@mantine/form';
import { useQuery } from '@tanstack/react-query';
import ChevronLeftIcon from 'components/atoms/Icon/chevron-left';
import React, { useContext, useMemo } from 'react';
import ReviewContext from '../../../context/ReviewProvider';
import Button from '../../atoms/Button/Button';
import Container from '../../atoms/Container/Container';
import ReviewCard from '../../atoms/ReviewCard/ReviewCard';
import ReviewForm from '../Reviews/ReviewForm';
import * as pcStyles from './ReviewSection.module.scss';
import * as mpsStyles from './ReviewSection.mps.module.scss';
import { storeStyle } from '../../../common/util';

const styles = storeStyle({mpsStyles, pcStyles});
const ReviewSection = ({ product }) => {
  const ctxReview = useContext(ReviewContext);
  const showReviewForm = ctxReview && ctxReview.showReviewForm;
  const form = useForm({
    initialValues: {
      page: undefined
    }
  });

  const { data: reviews, isLoading } = useQuery(
    ['product-review', product.entityId, form.values.page],
    async () => {
      const response = await ctxReview.getReviews(
        product.entityId,
        form.values.page
      );

      if (form.values.page === undefined) {
        form.setFieldValue('page', response?.meta?.pagination?.total_pages);
      }

      if (response?.data?.length) response?.data?.reverse();

      return response;
    }
  );

  const getPages = useMemo(() => {
    const pages = [];

    // The startPage should now be the lesser value between the current page + 1 or the total pages.
    const startPage = Math.min(
      reviews?.meta?.pagination?.total_pages,
      reviews?.meta?.pagination?.current_page + 1
    );

    // The endPage should now be the larger value between 1 or startPage - 2 (to show 3 pages in pagination).
    const endPage = Math.max(1, startPage - 2);

    // Loop backwards, since we want to show newer reviews first.
    for (let i = startPage; i >= endPage; i--) {
      pages.push(i);
    }

    return pages;
  }, [
    reviews?.meta?.pagination?.total_pages,
    reviews?.meta?.pagination?.current_page
  ]);

  const scrollToReviews = () => {
    document.getElementById('reviews').scrollIntoView();
  }

  return (
    <section className={styles.Reviews} id="reviews">
      <Container size="large">
        <div className="flex-between">
          <h4>{reviews?.meta?.pagination?.total ?? 0} Reviews</h4>
          <Button
            type={'span'}
            onClick={showReviewForm}
            level={'ghost'}
            className={styles.write}
          >
            LEAVE REVIEW
          </Button>
        </div>
        <div className={styles.ReviewsContainer}>
          {isLoading ? (
            <>
              <Box padding="6" bg="white">
                <SkeletonCircle size="10" />
                <SkeletonText
                  mt="4"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="2"
                />
              </Box>
              <Box padding="6" bg="white">
                <SkeletonCircle size="10" />
                <SkeletonText
                  mt="4"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="2"
                />
              </Box>
            </>
          ) : (
            <>
              {reviews?.data?.length > 0 ? (
                reviews?.data?.map(review => (
                  <ReviewCard
                    key={review.id}
                    name={review.name}
                    date={review.date_reviewed}
                    rating={review.rating}
                    content={review.text}
                    title={review.title}
                  />
                ))
              ) : (
                <Center h={300}>
                  <h3>No reviews yet</h3>
                </Center>
              )}
            </>
          )}
        </div>
        <div>
          {reviews && reviews?.meta?.pagination.total_pages > 1 && (
            <div className={styles.Pagination}>
              <>

                {/* {form.values.page !== reviews?.meta?.pagination.total_pages && ( */}
                    <button
                      className="first"
                      disabled={reviews?.meta?.pagination.current_page === reviews?.meta?.pagination.total_pages}
                      onClick={() => {
                        // form.setFieldValue(
                        //   'page',
                        //   reviews?.meta?.pagination.total_pages
                        // );
                        form.setFieldValue('page', reviews?.meta?.pagination.current_page + 1);
                        scrollToReviews();
                        }
                      }
                    >
                      <ChevronLeftIcon />
                    </button>
                {/* )} */}

                {(reviews?.meta?.pagination.total_pages - 1) > form.values.page  && (
                <button
                    className="last"
                    disabled={form.values.page === reviews?.meta?.pagination.total_pages}
                    onClick={() => {
                      form.setFieldValue('page', reviews?.meta?.pagination.total_pages);
                      scrollToReviews();
                    }}
                  >
                    1
                </button>
                )}

                {(reviews?.meta?.pagination.total_pages - 2) > form.values.page  && (
                  <>...</>
                )}

                {getPages.map(pageNum => (
                  <button
                    key={pageNum}
                    onClick={() => {
                      form.setFieldValue('page', pageNum);
                      scrollToReviews();
                    }}
                    disabled={pageNum === form.values.page ? 'active' : ''}
                    className={pageNum === form.values.page ? 'active' : ''}
                  >
                    {pageNum === reviews?.meta?.pagination.total_pages ? 1 : ((reviews?.meta?.pagination.total_pages - pageNum) + 1)}
                  </button>
                ))}

                {form.values.page > 3 && (
                  <>...</>
                )}

                {form.values.page > 2 && (
                  <>
                  <button
                    className="last"
                    disabled={form.values.page === 1}
                    onClick={() => {
                      form.setFieldValue('page', 1);
                      scrollToReviews();
                    }}
                  >
                    {reviews?.meta?.pagination.total_pages}
                  </button>
                  </>
                )}
                {/* {form.values.page !== 1 && ( */}
                  <button
                    className="last"
                    disabled={form.values.page === 1}
                    onClick={() => {
                      // form.setFieldValue('page', 1);
                      form.setFieldValue('page', reviews?.meta?.pagination.current_page - 1);
                      scrollToReviews();
                    }}
                  >
                    <ChevronLeftIcon />
                  </button>
                {/* )} */}
              </>
            </div>
          )}
        </div>
      </Container>
      <ReviewForm product={product} />
    </section>
  );
};

export default ReviewSection;
