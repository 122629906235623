// extracted by mini-css-extract-plugin
export var Faqs = "Product-module--Faqs--5d547";
export var accordionHeaderRootCustom = "Product-module--accordionHeaderRootCustom--7f620";
export var accordionTitleCustom = "Product-module--accordionTitleCustom--b2abf";
export var bestSelling = "Product-module--bestSelling--19019";
export var breadcrumbs = "Product-module--breadcrumbs--4af09";
export var colDetails = "Product-module--colDetails--c2d61";
export var colImages = "Product-module--colImages--9f358";
export var colThumbnails = "Product-module--colThumbnails--6f4e5";
export var discount = "Product-module--discount--e6849";
export var gradient = "Product-module--gradient--63a95";
export var iconContainerCustom = "Product-module--iconContainerCustom--1efa9";
export var noReviews = "Product-module--noReviews--53889";
export var productCategories = "Product-module--productCategories--24a12";
export var productContainer = "Product-module--productContainer--201b1";
export var productTop = "Product-module--productTop--cb284";
export var productTopCol = "Product-module--productTopCol--8a650";
export var productTopEntry = "Product-module--productTopEntry--c1526";
export var recentlyViewedProducts = "Product-module--recentlyViewedProducts--27271";
export var similarHeader = "Product-module--similarHeader--d9228";