import { Input, Select, Textarea, useToast } from '@chakra-ui/react';
import { useForm } from '@mantine/form';
import React, { useContext, useState } from 'react';
import ReviewContext from '../../../context/ReviewProvider';
import Button from '../../atoms/Button/Button';
import Dialog from '../../atoms/Dialog/Dialog';
import * as styles from './ReviewForm.module.css';

const ReviewForm = ({ product, onReload = () => {} }) => {
  const ctxReview = useContext(ReviewContext);

  const showReviewForm = ctxReview && ctxReview.showReviewForm;
  const [errorState] = useState({ title: '', errors: {} });
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const form = useForm({
    initialValues: {
      rating: 0,
      name: '',
      email: '',
      title: '',
      text: '',
      status: 'pending',
      date_reviewed: new Date()
    }
  });

  const handleCancel = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
  };

  const submitReviewForm = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await ctxReview.addReview(product.entityId, form.values);
      toast({
        colorScheme: 'primary',
        title: 'Review submitted.',
        description: 'Thank your for reviewing this product.',
        status: 'success',
        position: 'top-right'
      });
      ctxReview.showReviewForm(false);
      form.reset()
    } catch (_) {
      toast({
        colorScheme: 'primary',
        title: 'Review failed.',
        description: 'Something went wrong while submitting your review.',
        status: 'error',
        position: 'top-right'
      });
    } finally {
      setLoading(false);
    }

  };

  const closeModal = () => {
    showReviewForm(false);
  };

  return (
    <>
      {ctxReview && (
        <Dialog
          open={ctxReview.state.open}
          bindState={ctxReview && ctxReview.showReviewForm}
          title="Write a Review"
          hideBtnOk
          hideBtnCancel
          disableBackdropClick
          onClose={(event, reason) => handleCancel(event, reason)}
        >
          <div className={`${styles.reviewForm}`}>
            <div>
              {product && (
                <>
                  {product.images.length > 0 && (
                    <img
                      loading="lazy"
                      src={product.images.find( img => img.is_thumbnail === true)?.url_standard}
                      alt="Review Product"
                    />
                  )}
                  {product.categories.length > 1 && (
                    <h6>{product.categories[1].name}</h6>
                  )}
                  <h4>{product.name}</h4>
                </>
              )}
            </div>
            <div>
              <form
                className={`form ${styles.writeReviewForm}`}
                method="post"
                onSubmit={submitReviewForm}
              >
                <fieldset className="form-fieldset">
                  <div className="form-field">
                    <label className="form-label" htmlFor="rating-rate">
                      Rating <sup>*</sup>
                    </label>
                    <Select
                      required
                      {...form.getInputProps('rating')}
                      id="rating-rate"
                      className="form-select"
                      name="rating"
                    >
                      <option value="">Select Rating</option>
                      <option value="1">1 star (worst)</option>
                      <option value="2">2 stars</option>
                      <option value="3">3 stars (average)</option>
                      <option value="4">4 stars</option>
                      <option value="5">5 stars (best)</option>
                    </Select>
                  </div>
                  <div className="form-field">
                    <label className="form-label" htmlFor="revfromname_input">
                      Name <sup>*</sup>
                    </label>
                    <Input
                      required
                      {...form.getInputProps('name')}
                      type="text"
                      name="name"
                      id="revfromname_input"
                      className="form-input"
                      data-label="Name"
                    />
                  </div>
                  <div className="form-field">
                    <label className="form-label" htmlFor="email_input">
                      Email <sup>*</sup>
                    </label>
                    <Input
                      required
                      {...form.getInputProps('email')}
                      type="text"
                      name="email"
                      id="email_input"
                      className="form-input"
                    />
                  </div>
                  <div className="form-field">
                    <label className="form-label" htmlFor="revtitle_input">
                      Review Subject <sup>*</sup>
                    </label>
                    <Input
                      required
                      {...form.getInputProps('title')}
                      type="text"
                      name="title"
                      id="revtitle_input"
                      className="form-input"
                    />
                  </div>
                  <div className="form-field form-field--textarea">
                    <label className="form-label" htmlFor="revtext_input">
                      Comments <sup>*</sup>
                    </label>
                    <Textarea
                      required
                      {...form.getInputProps('text')}
                      name="text"
                      id="revtext_input"
                      className="form-input"
                    ></Textarea>
                  </div>

                  {errorState.errors &&
                    Object.keys(errorState.errors).length > 0 && (
                      <ul className="form-field form-field--errors">
                        {Object.values(errorState.errors).map((a, i) => (
                          <li key={i}>{a}</li>
                        ))}
                      </ul>
                    )}

                  {errorState.data && (
                    <div className="form-field form-field--success">
                      Your review has been submitted!
                    </div>
                  )}

                  <div className="form-field form-field--submit">
                    <Button
                      type="inputSubmit"
                      level="primary"
                      loading={loading}
                      disabled={loading}
                      value="Submit Review"
                      className="small"
                    />
                  </div>
                </fieldset>
              </form>
            </div>
          </div>

          <div className={`${styles.confirmation} `}>
            <h5>Thank you for your review!</h5>
            <Button onClick={() => closeModal()}>Close</Button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default ReviewForm;
