// extracted by mini-css-extract-plugin
export var Faqs = "Product-mps-module--Faqs--f2d6a";
export var accordionContentCustom = "Product-mps-module--accordionContentCustom--5a31c";
export var accordionHeaderRootCustom = "Product-mps-module--accordionHeaderRootCustom--e054b";
export var accordionTitleCustom = "Product-mps-module--accordionTitleCustom--cdf51";
export var bestSelling = "Product-mps-module--bestSelling--52291";
export var breadcrumbs = "Product-mps-module--breadcrumbs--ea77e";
export var colDetails = "Product-mps-module--colDetails--9f9ae";
export var colImages = "Product-mps-module--colImages--65bf1";
export var colThumbnails = "Product-mps-module--colThumbnails--90838";
export var discount = "Product-mps-module--discount--1fa0b";
export var gradient = "Product-mps-module--gradient--0a71b";
export var iconContainerCustom = "Product-mps-module--iconContainerCustom--b3b3c";
export var noReviews = "Product-mps-module--noReviews--63cba";
export var productCategories = "Product-mps-module--productCategories--80c9f";
export var productContainer = "Product-mps-module--productContainer--40838";
export var productTop = "Product-mps-module--productTop--9d0bf";
export var productTopCol = "Product-mps-module--productTopCol--7a581";
export var productTopEntry = "Product-mps-module--productTopEntry--8b9c8";
export var recentlyViewedProducts = "Product-mps-module--recentlyViewedProducts--b9482";
export var similarHeader = "Product-mps-module--similarHeader--e78c6";