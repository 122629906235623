import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Container from '../../atoms/Container/Container';
import * as pcStyles from './ProductInformation.module.css';
import * as mpsStyles from './ProductInformation.mps.module.scss';
import { storeStyle, isMadPawsStore } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const ProductInformation = ({ description }) => {
  const [tabData, setTabData] = useState([]);

  const [isMobile] = useMediaQuery('(max-width: 800px)', {
    ssr: true,
    fallback: false
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const parser = new DOMParser();
      const doc = parser.parseFromString(description, 'text/html');

      const isDefault = doc.body.firstElementChild?.nodeName.toLowerCase() !== 'table';

      if (isDefault) {
        setTabData([
          {
            title: 'Product info',
            content: description
          }
        ]);
        return;
      }

      const rows = Array.from(doc.querySelectorAll('body > table > tbody > tr'));
      const data = rows.map(row => {
        // Only select direct td children of the current row
        const cells = Array.from(row?.querySelectorAll('td'));
        return {
          title: cells[0]?.textContent,
          content: cells[1]?.innerHTML
        };
      });

      setTabData(data);
    }
  }, [description]);
  const madPawsStoreColor = isMadPawsStore() ? 'var(--primary-default)' : '#006E95';
  return (
    <Box className={styles.root}>
      <Container size="large">
        {!isMobile ? (
          <Tabs>
            <Box overflowX={'auto'} overflowY={'hidden'}>
              <TabList
                tabIndex={-1}
                borderBottom={'1px solid var(--greyscale-light-3-x, #EBEBEB);'}
                fontWeight={'bold'}
                gap={4}
                sx={{
                  _active: {
                    color: madPawsStoreColor
                  }
                }}
              >
                {tabData?.map((tab, index) => (
                  <Tab
                    _hover={{
                      background: isMadPawsStore() ? '#E9F2F2' : 'var(--blue-02)'
                    }}
                    px={1}
                    py={4}
                    tabIndex={0}
                    color={'#5D6975'}
                    _selected={{
                      color: madPawsStoreColor,
                      border: 'none',
                      boxShadow: `0px -4px 0px 0px ${madPawsStoreColor} inset;`
                    }}
                    whiteSpace={'nowrap'}
                    key={index}
                  >
                    {tab.title}
                  </Tab>
                ))}
              </TabList>
            </Box>

            <TabPanels maxWidth={'795px'}>
              {tabData?.map((tab, index) => (
                <TabPanel
                  key={index}
                  px={0}
                  pt={10}
                  className={styles.tabPanel}
                  dangerouslySetInnerHTML={{ __html: tab.content }}
                />
              ))}
            </TabPanels>
          </Tabs>
        ) : (
          <Accordion
            allowToggle
            borderColor={'#E6ECF2'}
            defaultIndex={0}
            defaultValue={0}
            defaultChecked={0}
          >
            {tabData?.map((tab, index) => (
              <AccordionItem key={index}>
                <Text color={'var(--charcoal-02)'}>
                  <AccordionButton px={0} py={6}>
                    <Box as="span" flex="1" textAlign="left" fontWeight={'semibold'}>
                      {tab.title}
                    </Box>
                    <AccordionIcon h={26} w={26} />
                  </AccordionButton>
                </Text>
                <AccordionPanel
                  p={0}
                  py={4}
                  className={styles.accordionPanel}
                  dangerouslySetInnerHTML={{ __html: tab.content }}
                ></AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </Container>
    </Box>
  );
};

export default ProductInformation;
