import { Box } from '@chakra-ui/react';
import CartContext from 'context/CartProvider';
import useSegment from 'hooks/useSegment';
import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import NewProductCard from '../../atoms/NewProductCard/NewProductCard';
import * as styles from './SimilarProducts.module.scss';

const SimilarProducts = ({ products }) => {
  const [isDragging, setIsDragging] = useState(false);
  const { track } = useSegment();
  const ctx = useContext(CartContext);
  const cart = ctx?.state?.cart?.lineItems?.physical_items;

  useEffect(() => {
    if (products?.length > 0) {
      track('Product List Viewed', {
        list_id:'Related Products',
        products: products.map((product, index) => ({
          name: product.name,
          sku: product.sku,
          brand: product.brand_id,
          price: product.calculated_price,
          product_id: product.entityId,
          url: product.custom_url.url,
          position: index + 1
        }))
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);





  return (
    <Box className={styles.root}>
      {products && products.length > 0 && (
        <Slider
          beforeChange={() => setIsDragging(true)}
          afterChange={() => setIsDragging(false)}
          dots={true}
          infinite={false}
          autoplay={false}
          slidesToShow={4}
          slidesToScroll={1}
          centerPadding="20px"
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                initialSlide: 1
              } // turns off slick on screens smaller than 500px
            },
            {
              breakpoint: 786,
              settings: {
                slidesToShow: 2,
                initialSlide: 1
              } // turns off slick on screens smaller than 500px
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                centerMode: true,
                initialSlide: 1
              } // turns off slick on screens smaller than 500px
            }
          ]}
        >
          {products?.map((product, index) => (
            <NewProductCard
              name={product.name}
              image={product.defaultImage?.url}
              price={product.calculated_price}
              key={index}
              buttonSpace
              sku={product.sku}
              productId={product.entityId}
              variantId={product.variants?.find(variant => variant.inventory_level > 0)?.id || product.variants?.[0]?.id}
              hasMoreOptions={product.variants.length > 1}
              url={product.custom_url.url}
              salePrice={product.sale_price}
              ratingCount={product.reviews_count}
              rating={product.reviews_rating_sum}
              intercept={isDragging}
              addToCart={true}
              showIcon
              skipCartModal={true}
              disabled={!!cart?.find(item => item.product_id === product.entityId)}
              autoshipDiscount={product.autoshipDiscount}
              variants={product.variants}
            />
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default SimilarProducts;
